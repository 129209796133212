export const ROOT_PATH = '/weight-loss'

// initial onboarding session to validate ID/collect weight
const WEIGHT_LOSS_SETUP = 'WEIGHT_LOSS_SETUP'
// first weekly check in session which includes more robust instructions for administering the medication
const WEIGHT_LOSS_CHECK_IN_STARTUP = 'WEIGHT_LOSS_CHECK_IN_STARTUP'
// first weekly check in session with no proctor
const WEIGHT_LOSS_CHECK_IN_STARTUP_NO_PROCTOR =
  'WEIGHT_LOSS_CHECK_IN_STARTUP_NO_PROCTOR'
// regular weekly check in session
const WEIGHT_LOSS_CHECK_IN = 'WEIGHT_LOSS_CHECK_IN'
// regular weekly check in session with no proctor
const WEIGHT_LOSS_CHECK_IN_NO_PROCTOR = 'WEIGHT_LOSS_CHECK_IN_NO_PROCTOR'
// refill monthly check in session
const WEIGHT_LOSS_CHECK_IN_REFILL = 'WEIGHT_LOSS_CHECK_IN_REFILL'
// refill questionnaire with no check-in
const WEIGHT_LOSS_REFILL = 'WEIGHT_LOSS_REFILL'
// Checkout
const WEIGHT_LOSS_CHECKOUT = 'WEIGHT_LOSS_CHECKOUT'
// Dashboard containing weighht loss progress
const WEIGHT_LOSS_DASHBOARD = 'WEIGHT_LOSS_DASHBOARD'

const entrypoints = {
  [WEIGHT_LOSS_SETUP]: 'setup',
  [WEIGHT_LOSS_CHECK_IN_STARTUP]: 'check-in-startup',
  [WEIGHT_LOSS_CHECK_IN]: 'check-in',
  [WEIGHT_LOSS_CHECK_IN_REFILL]: 'refill',
  [WEIGHT_LOSS_REFILL]: 'refill-questionnaire',
  [WEIGHT_LOSS_CHECKOUT]: 'checkout',
  [WEIGHT_LOSS_DASHBOARD]: 'dashboard',
}

export const procedures = {
  [WEIGHT_LOSS_SETUP]: WEIGHT_LOSS_SETUP,
  [WEIGHT_LOSS_CHECK_IN_STARTUP]: WEIGHT_LOSS_CHECK_IN_STARTUP,
  [WEIGHT_LOSS_CHECK_IN_STARTUP_NO_PROCTOR]: WEIGHT_LOSS_CHECK_IN_STARTUP_NO_PROCTOR,
  [WEIGHT_LOSS_CHECK_IN]: WEIGHT_LOSS_CHECK_IN,
  [WEIGHT_LOSS_CHECK_IN_NO_PROCTOR]: WEIGHT_LOSS_CHECK_IN_NO_PROCTOR,
  [WEIGHT_LOSS_CHECK_IN_REFILL]: WEIGHT_LOSS_CHECK_IN_REFILL,
}

export const setupRoutes = {
  home: `${ROOT_PATH}/${entrypoints[WEIGHT_LOSS_SETUP]}`,
  questionnaire: `${ROOT_PATH}/${entrypoints[WEIGHT_LOSS_SETUP]}/questionnaire`,
  nextSteps: `${ROOT_PATH}/${entrypoints[WEIGHT_LOSS_SETUP]}/next-steps`,
  gpInfo: `${ROOT_PATH}/${entrypoints[WEIGHT_LOSS_SETUP]}/gp-info`,
  confirmation: `${ROOT_PATH}/${entrypoints[WEIGHT_LOSS_SETUP]}/confirmation`,
  error: `${ROOT_PATH}/${entrypoints[WEIGHT_LOSS_SETUP]}/error`,
  errorIneligible: `${ROOT_PATH}/${entrypoints[WEIGHT_LOSS_SETUP]}/ineligible`,
}

export const setupV2Routes = {
  questionnaire: `${ROOT_PATH}/${entrypoints[WEIGHT_LOSS_SETUP]}/v3/questionnaire`,
}

export const checkInStartupRoutes = {
  home: `${ROOT_PATH}/${entrypoints[WEIGHT_LOSS_CHECK_IN_STARTUP]}`,
  confirmation: `${ROOT_PATH}/${entrypoints[WEIGHT_LOSS_CHECK_IN_STARTUP]}/confirmation`,
}

export const checkInRoutes = {
  home: `${ROOT_PATH}/${entrypoints[WEIGHT_LOSS_CHECK_IN]}`,
  confirmation: `${ROOT_PATH}/${entrypoints[WEIGHT_LOSS_CHECK_IN]}/confirmation`,
  startAutomated: `${ROOT_PATH}/${entrypoints[WEIGHT_LOSS_CHECK_IN]}/start`,
}

export const refillRoutes = {
  home: `${ROOT_PATH}/${entrypoints[WEIGHT_LOSS_REFILL]}`,
  checkInHome: `${ROOT_PATH}/${entrypoints[WEIGHT_LOSS_CHECK_IN_REFILL]}`,
  questionnaire: `${ROOT_PATH}/${entrypoints[WEIGHT_LOSS_CHECK_IN_REFILL]}/questionnaire`,
  nextSteps: `${ROOT_PATH}/${entrypoints[WEIGHT_LOSS_CHECK_IN_REFILL]}/next-steps`,
  confirmation: `${ROOT_PATH}/${entrypoints[WEIGHT_LOSS_CHECK_IN_REFILL]}/confirmation`,
}

export const checkoutRoutes = {
  home: `${ROOT_PATH}/${entrypoints[WEIGHT_LOSS_CHECKOUT]}`,
}

export const dashboardRoutes = {
  home: `${ROOT_PATH}/${entrypoints[WEIGHT_LOSS_DASHBOARD]}`,
}
