/* eslint-disable react/prop-types */
import React from 'react'
import cn from 'classnames/bind'
import styles from './Button.module.scss'
import Spinner from '@/programmes/components/Spinner'

const cx = cn.bind(styles)

type Props = {
  onClick: (event: any) => void
  children: React.ReactNode
  loading?: boolean
  disabled?: boolean
  secondary?: boolean
  tertiary?: boolean
  green?: boolean
}

const Button: React.FC<Props> = ({
  onClick,
  children,
  loading,
  disabled,
  secondary,
  tertiary,
  green,
}) => (
  <button
    className={cx('progButton', { secondary }, { tertiary }, { green })}
    type="button"
    onClick={onClick}
    disabled={loading || disabled}
  >
    {children}
    {loading && <Spinner />}
  </button>
)

export default Button
