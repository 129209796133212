import { useCallback, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  useCurrentUserId,
  useIsWeightLossAutomatedCheckInEnabled,
} from '@/redux/selectors'
import {
  checkInRoutes,
  checkoutRoutes,
  setupRoutes,
  setupV2Routes,
} from '../routes'
import { QUESTIONNAIRE_FORM_DATA_KEY } from '@/programmes/components/Form/hooks/useForm'
import { useGetUserGpInfo } from '@/programmes/hooks/useGpInfo'

const SETUP_HOME_KEY = 'wl-setup-home-visited'
const SETUP_QUESTIONNAIRE_REF_KEY = 'wl-setup-questionnaire-ref'
const SETUP_QUESTIONNAIRE_TIMESTAMP_KEY = 'wl-setup-questionnaire-timestamp'
export const SETUP_QUESTIONNAIRE_VERSION_KEY = 'wl-setup-questionnaire-version'
export const SETUP_QUESTIONNAIRE_VERSION_V1 = 'v1'
export const SETUP_QUESTIONNAIRE_VERSION_V2 = 'v2'

const useHandleSetupHomeRedirect = () => {
  const history = useHistory()

  return useCallback(() => {
    if (localStorage.getItem(SETUP_HOME_KEY) === 'true') {
      history.push(setupRoutes.questionnaire)
    } else {
      localStorage.setItem(SETUP_HOME_KEY, 'true')
    }
  }, [history])
}

const useHandleSetupQuestionnaireRedirect = () => {
  const history = useHistory()
  const { pathname } = useLocation()
  const currentUserId = useCurrentUserId()

  return useCallback(() => {
    const userId = localStorage.getItem(SETUP_QUESTIONNAIRE_REF_KEY)
    const isQuestionnaireV2 = pathname === setupV2Routes.questionnaire
    localStorage.setItem(
      SETUP_QUESTIONNAIRE_VERSION_KEY,
      isQuestionnaireV2
        ? SETUP_QUESTIONNAIRE_VERSION_V2
        : SETUP_QUESTIONNAIRE_VERSION_V1
    )

    if (currentUserId !== userId) {
      return
    }

    const questionnaireTimestamp = localStorage.getItem(
      SETUP_QUESTIONNAIRE_TIMESTAMP_KEY
    )
    const currentTimestamp = new Date().getTime()
    const THREE_DAYS_IN_MILLISECONDS = 259200000

    if (
      currentTimestamp - Number(questionnaireTimestamp) <
      THREE_DAYS_IN_MILLISECONDS
    ) {
      history.push(checkoutRoutes.home)
    }
  }, [currentUserId, history, pathname])
}

const useHandleGpInfoRedirect = () => {
  const history = useHistory()
  const { getGpInfo, data } = useGetUserGpInfo(true)

  return useCallback(() => {
    getGpInfo()

    if (data?.gp_name) {
      history.push(setupRoutes.nextSteps)
    }
  }, [getGpInfo, data, history])
}

const useHandleCheckoutHomeRedirect = () => {
  const history = useHistory()
  const handleCheckoutHomeRedirect = useCallback(() => {
    const questionnaireData = sessionStorage.getItem(
      QUESTIONNAIRE_FORM_DATA_KEY
    )
    const { completed = false } = questionnaireData
      ? JSON.parse(questionnaireData)
      : {}

    if (!completed) {
      const questionnaireVersion = localStorage.getItem(
        SETUP_QUESTIONNAIRE_VERSION_KEY
      )
      const isQuestionnaireV2 =
        questionnaireVersion === SETUP_QUESTIONNAIRE_VERSION_V2
      localStorage.removeItem(SETUP_QUESTIONNAIRE_REF_KEY)
      localStorage.removeItem(SETUP_QUESTIONNAIRE_TIMESTAMP_KEY)
      history.push(
        isQuestionnaireV2
          ? setupV2Routes.questionnaire
          : setupRoutes.questionnaire
      )
    }
  }, [history])

  return handleCheckoutHomeRedirect
}

const useHandleCheckInHomeRedirect = () => {
  const history = useHistory()
  const weightLossAutomatedCheckInEnabled = useIsWeightLossAutomatedCheckInEnabled()
  const handleCheckInHomeRedirect = useCallback(() => {
    if (weightLossAutomatedCheckInEnabled) {
      history.push(checkInRoutes.startAutomated)
    }
  }, [history, weightLossAutomatedCheckInEnabled])

  return handleCheckInHomeRedirect
}

export const saveQuestionnaireTimestamp = (userId: string) => {
  localStorage.setItem(SETUP_QUESTIONNAIRE_REF_KEY, userId)
  localStorage.setItem(
    SETUP_QUESTIONNAIRE_TIMESTAMP_KEY,
    new Date().getTime().toString()
  )
}

const useHandlePageVisitAndRedirection = () => {
  const location = useLocation()
  // remove trailing slash if it exists
  const pathname = location.pathname.replace(/\/$/, '')
  const handleSetupHomeRedirect = useHandleSetupHomeRedirect()
  const handleSetupQuestionnaireRedirect = useHandleSetupQuestionnaireRedirect()
  const handleCheckoutHomeRedirect = useHandleCheckoutHomeRedirect()
  const handleCheckInHomeRedirect = useHandleCheckInHomeRedirect()
  const handleGpInfoRedirect = useHandleGpInfoRedirect()

  useEffect(() => {
    if (pathname === setupRoutes.home) {
      handleSetupHomeRedirect()
    }

    if (
      pathname === setupRoutes.questionnaire ||
      pathname === setupV2Routes.questionnaire
    ) {
      handleSetupQuestionnaireRedirect()
    }

    if (pathname === setupRoutes.gpInfo) {
      handleGpInfoRedirect()
    }

    if (pathname === checkoutRoutes.home) {
      handleCheckoutHomeRedirect()
    }

    if (pathname === checkInRoutes.home) {
      handleCheckInHomeRedirect()
    }
  }, [
    pathname,
    handleCheckoutHomeRedirect,
    handleSetupHomeRedirect,
    handleGpInfoRedirect,
    handleSetupQuestionnaireRedirect,
    handleCheckInHomeRedirect,
  ])
}

export default useHandlePageVisitAndRedirection
