import React from 'react'
import { useFormatMessage } from '@babylon/intl'
import { Avatar, Card, LinkAnchor, Text } from '@babylon/medkit'
import subscriptionIcon from './assets/heart.png'
import hospitalIcon from './assets/hospital.png'
import weightManagementIcon from './assets/chart.png'
import messages from './messages'
import styles from './DiscoverSection.module.scss'

const DiscoverSection = () => {
  const t = useFormatMessage()

  return (
    <section className={styles.section}>
      <Text className={styles.header} variant="h1" tag="h2">
        {t(messages.title)}
      </Text>
      <LinkAnchor
        className={styles.link}
        role="link"
        href="https://www.emed.com/uk/services"
        tabIndex={0}
      >
        <Card
          interactive
          thumbnail={
            <Avatar
              src={hospitalIcon}
              highlightIcon
              description={t(messages.privateClinicTitle)}
            />
          }
          title={t(messages.privateClinicTitle)}
          subTitle={t(messages.privateClinicSubtitle)}
        />
      </LinkAnchor>
      <LinkAnchor
        className={styles.link}
        role="link"
        href="https://www.emed.com/uk/subscribe-now-for-members"
        tabIndex={0}
      >
        <Card
          interactive
          thumbnail={
            <Avatar
              src={subscriptionIcon}
              highlightIcon
              description={t(messages.subscriptionTitle)}
            />
          }
          title={t(messages.subscriptionTitle)}
          subTitle={t(messages.subscriptionSubtitle)}
        />
      </LinkAnchor>
      <LinkAnchor
        className={styles.link}
        role="link"
        href="https://www.emed.com/uk/weight-management"
        tabIndex={0}
      >
        <Card
          interactive
          thumbnail={
            <Avatar
              src={weightManagementIcon}
              highlightIcon
              description={t(messages.weightManagementTitle)}
            />
          }
          title={t(messages.weightManagementTitle)}
          subTitle={t(messages.weightManagementSubtitle)}
        />
      </LinkAnchor>
    </section>
  )
}

export default DiscoverSection
